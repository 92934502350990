import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from "universal-cookie";
import styled from 'styled-components';
const VideoPlayerContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// Define a styled iframe for the YouTube video
const VideoFrame = styled.iframe`
  width: 100%;
  height: 400px; /* Adjust the height as needed */
`;
function SearchResults(props) {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');
  const cookies = new Cookies();

  useEffect(() => {
    // Fetch search results when the component mounts or when the query changes
    async function fetchResults() {
      try {
        console.log(cookies.get("token"))
        const response = await axios.get(`https://themajesticquran.doctorsforhealth.co.uk/api/majestic/search?q=${props.q}`,
        {
            method: "GET",
            headers: {
              Authorization: cookies.get("token"),
            },
          });
        setResults(response.data.data);
        console.log("response",response.data.data)
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }

    fetchResults();
  }, [query]);

  return (
    <div>
     <h3>Searching...</h3>
      <ul>
        {results.map((result) => (
          <li key={result._id}>
 
            {result.schemaType === 'podcast' && (
              <div>
              <h1>{result.title}</h1>
        <p>{result.desc}</p>
        <VideoFrame
          title="YouTube Video"
          src={`https://www.youtube.com/embed/${result.pid}`}
          frameBorder="1"
          allowFullScreen
        />
              </div>
            )}
            {result.schemaType === 'ayat' && (
              <div>
                {/* Render ayat-specific content */}
        
                <h1>{result.title}</h1>
                <p>{result.paragraph}</p>
                {/* Render other ayat-specific fields */}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchResults;
