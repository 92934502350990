import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Body = styled.body`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: auto;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  position: relative;
`;

const TitleBefore = styled.span`
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Input = styled.input`
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
`;

const ButtonContainer = styled.div`
  height: 45px;
  margin: 35px 0;
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;

const Addsurah = (props) => {
  const [surahnumber, setSurahnumber] = useState("");
  const [arabicname, setArabicname] = useState("");
  const [romanname, setRomanname] = useState("");
  const [surah_ayats, setSurah_ayats] = useState("");
  const [surahstoryheadingromanname, setSurahstoryheading] = useState("");
  const [surahStory, setSurahStory] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [title, setTitle] = useState("");
  const [footnotes, setFootnotes] = useState([]);
  const [qariaudio, setQariaudio] = useState([]);
  const [para, setPara] = useState("");
  const [word, setWord] = useState("");
  const [meaning, setMeaning] = useState("");
  const [input, setInput] = useState("");
  const [paraList, setParaList] = useState([]);
  const [showPara, setShowpara] = useState("");

  const cookies = new Cookies();


  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/para", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setShowpara(res.data);
        console.log("res para", res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong");
      });
  }, []);

  const addQariaudio = () => {
    const newQariaudio = {
      qari: "",
      qariaudio: "",
    };
    setQariaudio([...qariaudio, newQariaudio]);
  };

  const updateQariaudio = (index, field, value) => {
    const updatedQariaudio = [...qariaudio];
    updatedQariaudio[index][field] = value;
    setQariaudio(updatedQariaudio);
  };

  const removeQariaudio = (index) => {
    const updatedQariaudio = [...qariaudio];
    updatedQariaudio.splice(index, 1);
    setQariaudio(updatedQariaudio);
  };
  const addFootnote = () => {
    const newFootnote = {
      word: "",
      meaning: "",
    };
    setFootnotes([...footnotes, newFootnote]);
  };

  const updateFootnote = (index, field, value) => {
    const updatedFootnotes = [...footnotes];
    updatedFootnotes[index][field] = value;
    setFootnotes(updatedFootnotes);
  };

  const removeFootnote = (index) => {
    const updatedFootnotes = [...footnotes];
    updatedFootnotes.splice(index, 1);
    setFootnotes(updatedFootnotes);
  };

  const addPara = () => {
    if (para.trim() !== "") {
      setParaList([...paraList, para]);
      setPara(""); // Clear the input field
    }
  };

  const removePara = (index) => {
    const updatedParaList = [...paraList];
    updatedParaList.splice(index, 1);
    setParaList(updatedParaList);
  };

  const obj = {
    surah_number: surahnumber,
    arabic_name: arabicname,
    roman_name: romanname,
    surah_ayats:surah_ayats,
    surah_story_heading: surahstoryheadingromanname,
    surahStory:surahStory,
    introduction: introduction,
    title: title,
    footnotes: footnotes,
    audio: qariaudio,
    para: paraList,
  };
  const Confirm = (e) => {
    e.preventDefault();
    console.log(obj);

    if (
      obj.surah_number &&
      obj.arabic_name &&
      obj.roman_name &&
      obj.introduction &&
      obj.title &&
      obj.footnotes &&
      obj.para.length > 0 &&
      obj.audio
    ) {
      axios("https://themajesticquran.doctorsforhealth.co.uk/api/admin/AddSurah", {
        method: "POST",
        data: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.get("token"),
        },
      })
        .then((res) => {
          console.log(res.data);

          if (res) {
            alert("Successfully added");
          } else {
            alert(res);
          }
        })
        .catch(() => {
          alert("Try again");
        });
    } else {
      alert("Fill all Data");
    }
  };

  return (
    <Body>
      <Container>
        <Title>
          <h1>Add Surah</h1>
          <TitleBefore />
        </Title>

        <Content>
          <InputBox>
            <Label>Add surah number</Label>
            <Input
              type="number"
              value={surahnumber}
              onChange={(e) => setSurahnumber(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add surah name (in arabic)</Label>
            <Input
              type="text"
              value={arabicname}
              onChange={(e) => setArabicname(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add surah name (in roman)</Label>
            <Input
              type="text"
              value={romanname}
              onChange={(e) => setRomanname(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add surah ayats</Label>
            <Input
              type="number"
              value={surah_ayats}
              onChange={(e) => setSurah_ayats(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Surah Story Heading</Label>
            <Input
              type="text"
              value={surahstoryheadingromanname}
              onChange={(e) => setSurahstoryheading(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Surah Story</Label>
            <Input
              type="text"
              value={surahStory}
              onChange={(e) => setSurahStory(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add introduction</Label>
            <Input
              type="text"
              value={introduction}
              onChange={(e) => setIntroduction(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add title</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add Footness</Label>
            <Button onClick={addFootnote}>Add Footnote</Button>
            {footnotes.map((footnote, index) => (
              <div key={index}>
                <Label>Word</Label>
                <Input
                  type="text"
                  value={footnote.word}
                  onChange={(e) =>
                    updateFootnote(index, "word", e.target.value)
                  }
                  placeholder="write word"
                />
                <Input
                  type="text"
                  value={footnote.meaning}
                  onChange={(e) =>
                    updateFootnote(index, "meaning", e.target.value)
                  }
                  placeholder="Explaination of word"
                />
                <Button onClick={() => removeFootnote(index)}>Remove</Button>
              </div>
            ))}
          </InputBox>
          <InputBox>
            <Label>Add Qari Audio</Label>
            <Button onClick={addQariaudio}>Add Qari</Button>
            {qariaudio.map((qariaudio, index) => (
              <div key={index}>
                <Label>Qari Name</Label>
                <Input
                  type="text"
                  value={qariaudio.qari}
                  onChange={(e) =>
                    updateQariaudio(index, "qari", e.target.value)
                  }
                  placeholder="write qari name"
                />
                <Input
                  type="text"
                  value={qariaudio.qariaudio}
                  onChange={(e) =>
                    updateQariaudio(index, "qariaudio", e.target.value)
                  }
                  placeholder="Qari audio link"
                />
                <Button onClick={() => removeQariaudio(index)}>Remove</Button>
              </div>
            ))}
          </InputBox>
          {/* <InputBox>
            <Label>Select Para</Label>
            <Input
              type="text"
              value={para}
              onChange={(e) => setPara(e.target.value)}
            />
          </InputBox> */}

          <InputBox>
            <Label>Add Para</Label>
            {/* <Input
              type="text"
              value={para}
              onChange={(e) => setPara(e.target.value)}
              placeholder="Enter Para"
            /> */}
            <Button onClick={addPara}>Add Para</Button>
            {paraList.map((item, index) => (
              <div key={index}>
                <span>{item}</span>
                <Button onClick={() => removePara(index)}>Remove</Button>
              </div>
            ))}
          </InputBox>
          {showPara?(
                 <select onChange={(e) => setPara(e.target.value)}>
                 <option value="" disabled selected>
                   Para
                 </option>
                 {showPara.map((para) => (
                   <option key={para._id} value={para.arabic_name}>
                     {para.arabic_name}
                   </option>
                 ))}
               </select>

          ):(
            <p>Waiting for para</p>
          )}
     
          <ButtonContainer>
            <Button onClick={Confirm}>Confirm</Button>
          </ButtonContainer>

          <ButtonContainer>
            <Button onClick={props.handleButtonClick}>Cancel</Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Body>
  );
};

export default Addsurah;
