import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Cookies from "universal-cookie";
import axios from "axios";
import UpdatePodcast from "./update/Updatepodcast"

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// Define a styled container for the video player
const VideoPlayerContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// Define a styled iframe for the YouTube video
const VideoFrame = styled.iframe`
  width: 100%;
  height: 400px; /* Adjust the height as needed */
`;

const Showpodcast = () => {
  // Construct the YouTube embed URL with the video ID
  const [podcastData, setPodcastData] = useState([])
  const [updatepodcast, setUpdatepodcast] = useState(false);
  const cookies = new Cookies();

  const handleUpdatepodcast = () => {
    setUpdatepodcast(!updatepodcast)
  }
  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/Showpodcast", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setPodcastData(res.data.data);
        console.log("res for podcast", res.data.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong in podcast");
      });
  }, []);


  return (
    <>

      <VideoPlayerContainer>
  {podcastData.length > 0 ? (
    podcastData.map((data, index) => (
      <div key={data._id}>
      {updatepodcast && <UpdatePodcast handleButtonClick={handleUpdatepodcast } id={data._id}/>}
        <h1>{data.title}</h1>
        <p>{data.desc}</p>
        <Button onClick={handleUpdatepodcast}>Update</Button>
        <VideoFrame
          title="YouTube Video"
          src={`${data.pid}`}
          frameBorder="1"
          allowFullScreen
        />
      </div>
      
    ))
  ) : (
    <p>Loading.....</p>
  )}
</VideoPlayerContainer>


    </>
  );
};

export default Showpodcast;
