// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import Cookies from "universal-cookie";
// import styled from "styled-components";

// const DetailPageContainer = styled.div`
//   padding: 20px;
//   background-color: #f7f7f7;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   margin: 20px;
// `;

// const Title = styled.h2`
//   font-size: 24px;
//   color: #333;
// `;

// const Paragraph = styled.p`
//   font-size: 16px;
//   margin-bottom: 10px;
// `;

// const Bookmarked = () => {
//   const { roman_name } = useParams();
//   const cookies = new Cookies();
//   const [detail, setDetail] = useState(null); // Initialize as null for loading state
//   const [error, setError] = useState(null); // Initialize as null for error handling
//   const [receiveAyat, setReceiveAyat] = useState(null); // Initialize as null for error handling
//   const [bookmarkid, setBookmarkid] = useState("");
  
//   useEffect(() => {
//     axios(
//       `http://localhost:2000/api/majestic/getbookmark`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: cookies.get("token"),
//         },
//       }
//     )
//       .then((res) => {
//         if(res.status==200){
//             setDetail(res.data.message);
//             console.log("book mark ayat", res.data.message);
//         }
        
//         // setReceiveAyat(res.data[0].ayat);

//         console.log("book mark ayat", res.data);
//       })
//       .catch((err) => {
//         setError(err.message);
//       });
//   }, []);
//   useEffect(() => {
//     axios(
//       `http://localhost:2000/api/majestic/getsurah?id=${detail.surah}`,
//       {
//         method: "GET",
//         headers: {
//           Authorization: cookies.get("token"),
//         },
//       }
//     )
//       .then((res) => {
//         if(res.status==200){
//             // setDetail(res.data.message);
//             console.log("book mark surah", res.data.message);
//         }
        
//         // setReceiveAyat(res.data[0].ayat);

//         console.log("book mark ayat", res.data);
//       })
//       .catch((err) => {
//         setError(err.message);
//       });
//   }, [detail]);


//   return (
  
//     <>
//     <DetailPageContainer>
//       {detail ? (

//           <div>
            
//             <Title>Starting Ayat:{detail.starting_ayat}</Title>
//             <Title>Ending Ayat:{detail.ending_ayat}</Title>
//             <Title>{detail.surah}</Title>
//             <Title>{detail.para}</Title>
//             {/* <Paragraph>{detailt.ayat}</Paragraph>
//             <Paragraph>{content._id}</Paragraph> */}
  
//           </div>
      
//         )
//        : (
//         <p>Loading...</p>
//       )}
//     </DetailPageContainer>
//   </>
//   );
// };

// export default Bookmarked;


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components";

const DetailPageContainer = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

const Bookmarked = () => {
  const { roman_name } = useParams();
  const cookies = new Cookies();
  const [detail, setDetail] = useState(null);
  const [error, setError] = useState(null);
  const [surah,setSurah]=useState("")
  const [para,setPara]=useState("")

  useEffect(() => {
    axios(
      `http://localhost:2000/api/majestic/getbookmark`,
      {
        method: "GET",
        headers: {
          Authorization: cookies.get("token"),
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setDetail(res.data.message);
          console.log("book mark ayat", res.data.message);
        }
      })
      .catch((err) => {
        setError(err.message);
        console.error("Error fetching bookmarks:", err);
      });
  }, []);

  useEffect(() => {
    if (detail && detail.surah) {
      axios(
        `http://localhost:2000/api/majestic/getsurah?id=${detail.surah}`,
        {
          method: "GET",
          headers: {
            Authorization: cookies.get("token"),
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setSurah(res.data)
            console.log("book mark surah", res.data.roman_name);
          }
        })
        .catch((err) => {
          setError(err.message);
          console.error("Error fetching surah:", err);
        });
    }
  }, [detail]);
  useEffect(() => {
    if (detail && detail.para) {
      axios(
        `http://localhost:2000/api/majestic/getpara?id=${detail.para}`,
        {
          method: "GET",
          headers: {
            Authorization: cookies.get("token"),
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setPara(res.data)
            console.log("book mark para", res.data);
          }
        })
        .catch((err) => {
          setError(err.message);
          console.error("Error fetching surah:", err);
        });
    }
  }, [detail]);

  return (
    <DetailPageContainer>
      {detail ? (
        <div>
        <Title>Para No. : {para.para_number}</Title>
        <p>Para Name : {para.roman_name}</p>
        <p>Surah Number : {surah.surah_number}</p>
          <p>Surah Name :{surah.roman_name}</p>
          <Title>Title : {detail.title}</Title>
          
          <p>Starting Ayat : {detail.starting_ayat}</p>
          <p>Ending Ayat : {detail.ending_ayat}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </DetailPageContainer>
  );
};

export default Bookmarked;
