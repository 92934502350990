import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;
const ButtonContainer = styled.div`
  height: 45px;
  margin: 35px 0;
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Showaudioquran= (props) => {
  const data = props.showSurah;
  return (
    <>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Surah Number</TableHeader>
            <TableHeader>Surah Name</TableHeader>
            <TableHeader>Arabic Name</TableHeader>
            <TableHeader>Title</TableHeader>
            <TableHeader>Introduction</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow key={item.surah_number}>
            <TableCell>{item.surah_number}</TableCell>
              <TableCell>
                <Link to={`/detailaudio/${item.roman_name}`}>{item.roman_name}</Link>
                {/* <Link to={`http://localhost:2000/api/user/event?id=${item._id}`}>{item.title}</Link> */}
              </TableCell>
              <TableCell>{item.arabic_name}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.introduction}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <ButtonContainer>
        <Button onClick={props.handleButtonClick}>Cancel</Button>
      </ButtonContainer>
    </>
  );
};

export default Showaudioquran;
