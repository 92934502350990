import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Body = styled.body`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: auto;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  position: relative;
`;

const TitleBefore = styled.span`
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Input = styled.input`
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
`;

const ButtonContainer = styled.div`
  height: 45px;
  margin: 35px 0;
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;

const Addayat= (props) => {
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [surah, setSurah] = useState("");
  const [para, setPara] = useState("");
  const [ayat, setAyat] = useState([]);
  const [starting_ayat, setStarting_ayat] = useState("");
  const [ending_ayat, setEnding_ayat] = useState("");
  const [showPara, setShowpara] = useState("");
  const [showSurah, setShowsurah] = useState("");

 
  const cookies = new Cookies();

  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/surah", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setShowsurah(res.data.message);
        console.log("res surah", res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong");
      });
  }, []);
  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/para", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setShowpara(res.data);
        console.log("res para", res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong");
      });
  }, []);

  // const addAyat = () => {
  //   const newAyat = {
  //     ayat_num: "",
  //     textayat: "",
  //   };
  //   setAyat([...ayat, newAyat]);
  // };

  // const updateAyat = (index, field, value) => {
  //   console.log("updated",index, field, value)
  //   const updatedAyat = [...ayat];
  //   updatedAyat[index][field] = value;
  //   setAyat(updatedAyat);
  // };

  // const removeAyat = (index) => {
  //   const updatedAyat = [...ayat];
  //   updatedAyat.splice(index, 1);
  //   setAyat(updatedAyat);
  // };


  const obj = {
    title:title,
    starting_ayat:starting_ayat,
    ending_ayat:ending_ayat,
    ayat:ayat,
    paragraph:paragraph,
    surah:surah,
    para:para,

  }
  const Confirm = (e) => {
    e.preventDefault()
    console.log(obj)


    if (obj.title && obj.starting_ayat && obj.ending_ayat && obj.ayat && obj.paragraph && 
        obj.surah && obj.para) {
      axios("https://themajesticquran.doctorsforhealth.co.uk/api/admin/AddAyat", {
        method: "POST",
        data: JSON.stringify(obj),
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get("token"),

        },
      })
        .then((res) => {
          console.log("data",res.data)

          if (res) {

            alert("successfully aayt is added")
          }
          
          else {
            alert(res)
          }


        })
        .catch((err) => {
          alert("Try again")
        })


    }
    else {
      alert("Fill all Data")
    }

  }

  return (
    <Body>
      <Container>
        <Title>
          <h1>Add Ayat</h1>
          <TitleBefore />
        </Title>

        <Content>
          <InputBox>
            <Label>Add smmary title</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Ayat starting from </Label>
            <Input
              type="Number"
              value={starting_ayat}
              onChange={(e) => setStarting_ayat(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Ayat ending at </Label>
            <Input
              type="Number"
              value={ending_ayat}
              onChange={(e) => setEnding_ayat(e.target.value)}
            />
          </InputBox>
         
          <InputBox>
            <Label>Add ayat</Label>
            <Input
              type="text"
              value={ayat}
              onChange={(e) => setAyat(e.target.value)}
            />
          </InputBox>
        
      
    
          {/* <InputBox>
            <Label>Add Ayat</Label>
            <Button onClick={addAyat}>Add Ayat</Button>
          {ayat.map((ayat, index) => (
              <div key={index}>
                <Label>Ayat number</Label>
                <Input
                  type="number"
                  value={ayat.ayat_num}
                  onChange={(e) => updateAyat(index, "ayat_num", e.target.value)}
                  placeholder="write ayat number"
                />
                <Input
                  type="text"
                  value={ayat.textayat}
                  onChange={(e) =>
                    updateAyat(index, "textayat", e.target.value)
                  }
                  placeholder="Ayat im arabic"
                />
                <Button onClick={() => removeAyat(index)}>Remove</Button>
              </div>
            ))}
          </InputBox> */}
          <InputBox>
            <Label>Add paragraph (in english)</Label>
            <Input
              type="text"
              value={paragraph}
              onChange={(e) => setParagraph(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Select Surah</Label>
            {/* <Input
              type="text"
              value={surah}
              onChange={(e) => setSurah(e.target.value)}
            /> */}
             {showSurah?(
                 <select onChange={(e) => setSurah(e.target.value)}>
                 <option value="" disabled selected>
                   Surah
                 </option>
                 {showSurah.map((surah) => (
                   <option key={surah._id} value={surah.arabic_name}>
                     {surah.arabic_name}
                   </option>
                 ))}
               </select>

          ):(
            <p>Waiting for surah</p>
          )}
            
          </InputBox>
          <InputBox>
            <Label>Select Para</Label>
            {/* <Input
              type="text"
              value={para}
              onChange={(e) => setPara(e.target.value)}
            /> */}
             {showPara?(
                 <select onChange={(e) => setPara(e.target.value)}>
                 <option value="" disabled selected>
                   Para
                 </option>
                 {showPara.map((para) => (
                   <option key={para._id} value={para.arabic_name}>
                     {para.arabic_name}
                   </option>
                 ))}
               </select>

          ):(
            <p>Waiting for para</p>
          )}
          </InputBox>
         

          <ButtonContainer>
            <Button onClick={Confirm}>Confirm</Button>
          </ButtonContainer>

          <ButtonContainer>
            <Button onClick={props.handleButtonClick}>Cancel</Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Body>
  );
};

export default Addayat;
