import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Body = styled.body`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: auto;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  position: relative;
`;

const TitleBefore = styled.span`
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Input = styled.input`
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
`;

const ButtonContainer = styled.div`
  height: 45px;
  margin: 35px 0;
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;

const Addpodcastform = (props) => {
  const [title, setTitle] = useState("");
 
  const [desc, setDesc] = useState("");
  const [id, setId] = useState("");
  const [newKey, setNewKey] = useState(""); // New key to add
  const [keys, setKeys] = useState([]);

  const cookies = new Cookies();

  const obj = {
    title:title,
    desc:desc,
    pid:id,
    keys:keys
  }

  const addKey = () => {
    if (newKey.trim() !== "") {
      setKeys([...keys, newKey]); // Add the new key to the keys array
      setNewKey(""); // Clear the input field
    }
  };

  const removeKey = (index) => {
    const updatedKeys = [...keys];
    updatedKeys.splice(index, 1); // Remove the key at the specified index
    setKeys(updatedKeys);
  };
  const Confirm = (e) => {
    e.preventDefault()
    console.log(obj,"id")


    if (obj.title && obj.desc && obj.pid &&  obj.keys.length > 0) {
      axios("https://themajesticquran.doctorsforhealth.co.uk/api/admin/Addpodcast", {
        method: "POST",
        data: JSON.stringify(obj),
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get("token"),

        },
      })
        .then((res) => {
          console.log(res.data)
            alert("Successfully added")
        


        })
        .catch((err) => {
          alert("Try again")
        })


    }
    else {
      alert("Fill all Data")
    }

  }

  return (
    <Body>
      <Container>
        <Title>
          <h1>Add podcast</h1>
          <TitleBefore />
        </Title>

        <Content>
          <InputBox>
            <Label>Add Title</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add Description</Label>
            <Input
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </InputBox>
          <InputBox>
            <Label>Add Podcast Id</Label>
            <Input
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </InputBox>
          <InputBox>
          <Label>Add Keys</Label>
          <div>
            <input
              type="text"
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
            />
            <button onClick={addKey}>Add</button>
          </div>
        </InputBox>
        <div>
          <Label>Keys:</Label>
          <ul>
            {keys.map((key, index) => (
              <li key={index}>
                {key} <button onClick={() => removeKey(index)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>

          <ButtonContainer>
            <Button onClick={Confirm}>Confirm</Button>
          </ButtonContainer>

          <ButtonContainer>
            <Button onClick={props.handleButtonClick}>Cancel</Button>
          </ButtonContainer>
        </Content>
      </Container>
    </Body>
  );
};

export default Addpodcastform;
