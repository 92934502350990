import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  height: 45px;
  margin: 35px 0;
`;
const Button = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;


const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const UserTable = (props) => {
    let data = props.data
    return (
        <>
      
        <Table>
            <thead>
                <TableRow>
                    <TableHeader>Id</TableHeader>
                    <TableHeader>Name</TableHeader>
                    {/* <TableHeader>email</TableHeader> */}
                    <TableHeader>Status</TableHeader>

                </TableRow>
            </thead>
            <tbody>
                {data.map((item) => (
                    <TableRow key={item._id}>
                        <TableCell>
                            {item._id}
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        {/* <TableCell>{item.email}</TableCell> */}
                        <TableCell>
                            {item.isBlock ? "Blocked" : "Active"}
                        </TableCell>
                    </TableRow>
                    
                ))}
            </tbody>
        </Table>
         <ButtonContainer>
         <Button onClick={props.handleButtonClick}>Cancel</Button>
       </ButtonContainer>
       </>
    );
};

export default UserTable;
