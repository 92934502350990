import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Adminuse from "./formblockuser";
import Unblockuser from "./formunblockuser";
import Cookies from "universal-cookie";
import axios from "axios";
import UserTable from "./viewuser";

import Addpara from "./formaddpara";
import Addsurah from "./formaddsurah";
import Addhadees from "./formaddhadees";
import Addayat from "./formaddayat";
import ShowarabicandEnglish from "./showarabicandenglish";
import Showquranarabic from "./showquranarabic";
import Showenglishquran from "./Showenglishquran";
import Addpodcastform from "./Addpodcast";
import Showpodcast from "./podcast";
import SearchResults from "./Search";
import SurahPlayer from "./Testaudio";
import Showaudioquran from "./showquranaudio";
import { Navigate, useNavigate } from 'react-router-dom';
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Adminpanel = () => {
  const [showAdminUseForm, setShowAdminUseForm] = useState(false);
  const [showSurah, setshowSurah] = useState("");
  const [showview, setShowview] = useState(false);
  const [showviewuser, setShowviewuser] = useState(false);
  const [showviewUser, setShowviewUser] = useState(false);
  const [showunblock, setShowunblock] = useState(false);
  const [showaddcategory, setShowaddcategory] = useState(false);
  const [showaddpara, setShowaddpara] = useState(false);
  const [showaddsurah, setShowaddsurah] = useState(false);
  const [showaddhadees, setShowaddhadees] = useState(false);
  const [showaddayat, setShowaddayat] = useState(false);
  const [showarabicandenglish, setShowarabicandenglish] = useState(false);
  const [showquranarabic, setShowquranarabic] = useState(false);
  const [showPara, setShowpara] = useState("");
  const [showPodcastform, setPodcastform] = useState("");
  const [showPodcast, setShowpodcast] = useState("");
  const [showquranenglish, setShowquranenglish] = useState(false);
  const [showsearch, setShowsearch] = useState(false);
  const [showaudioquran, setShowaudioquran] = useState(false);
  const [showaudio, setShowaudio] = useState(false);
  const [query, setQuery] = useState("");
  const [audio, setAudio] = useState("");
  const cookies = new Cookies();
  const navigate = useNavigate();
  const handleLogout = () => {
   
    cookies.remove('token');
    navigate('/');
  };

  const handleUnblock = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowaddcategory(false);
    setShowunblock(!showunblock);
    setShowaddsurah(false);
    setShowaddpara(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddcategory = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(!showaddcategory);
    setShowaddsurah(false);
    setShowaddpara(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleButtonClick = () => {
    setShowAdminUseForm(!showAdminUseForm);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddsurah(false);
    setShowaddpara(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleViewuser = () => {
    setShowAdminUseForm(false);
    setShowviewuser(!showviewuser);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddsurah(false);
    setShowaddpara(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddpara = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddsurah(false);
    setShowaddpara(!showaddpara);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowquranenglish(false);
    setShowpodcast(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddsurah = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(!showaddsurah);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddhadees = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(!showaddhadees);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowquranenglish(false);
    setShowpodcast(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddayat = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(!showaddayat);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleArabicandEnglish = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(!showarabicandenglish);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleQuranarabic = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(!showquranarabic);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleQuranenglish = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(!showquranenglish);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleAddpodcast = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(!showPodcastform);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleShowpodcast = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(!showPodcast);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudioquran(false)
  };
  const handleSearch = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(!showsearch);
    setShowaudioquran(false)
  };
  const handleAudio = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudio(!showaudio);
    setShowaudioquran(false)
  };

  const handleQuranAudio = () => {
    setShowAdminUseForm(false);
    setShowview(false);
    setShowunblock(false);
    setShowaddcategory(false);
    setShowaddpara(false);
    setShowaddsurah(false);
    setShowaddhadees(false);
    setShowaddayat(false);
    setShowarabicandenglish(false);
    setShowviewuser(false);
    setShowquranarabic(false);
    setPodcastform(false);
    setShowpodcast(false);
    setShowquranenglish(false);
    setShowsearch(false);
    setShowaudio(false);
    setShowaudioquran(!showaudioquran)
  };


  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/surah", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setshowSurah(res.data.message);
        console.log("res surah", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong");
      });
  }, []);
  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/majestic/para", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        setShowpara(res.data);
        console.log("res para", res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something is wrong");
      });
  }, []);


  useEffect(() => {
    axios("https://themajesticquran.doctorsforhealth.co.uk/api/admin/Viewuser", {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        console.log(" view user res", res.data.data);
        setShowviewUser(res.data.data);
    
      })
      .catch((err) => {
        console.log("err",err);
        alert("Something is wrong in viewuser");
      });
  }, []);
  // useEffect(() => {
  //   axios("http://localhost:2000/api/majestic/getaudio", {
  //     method: "GET",
  //     headers: {
  //       Authorization: cookies.get("token"),
  //     },
  //   })
  //     .then((res) => {
  //       setAudio(res.data);
  //       console.log("res audio",audio);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert("Something is wrong ");
  //     });
  // }, []);

  return (
    <>
      <ButtonContainer>
      <ButtonContainer>
          <Button onClick={handleLogout}>Logout</Button>
        </ButtonContainer>
        <Button onClick={handleViewuser}>View Users</Button>
        <Button onClick={handleButtonClick}>Block User</Button>
        <Button onClick={handleUnblock}>Unblock User</Button>
        <Button onClick={handleAddpara}>Add Para</Button>
        <Button onClick={handleAddsurah}>Add Surah</Button>
        <Button onClick={handleAddayat}>Add Ayat</Button>
        {/* <Button onClick={handleAddhadees}>Add hadees</Button> */}
        <Button onClick={handleArabicandEnglish}>Arabic and English</Button>
        <Button onClick={handleQuranarabic}>Quran Arabic</Button>
        <Button onClick={handleQuranenglish}>Quran English</Button>
        <Button onClick={handleAddpodcast}>Add Podcast</Button>
        <Button onClick={handleShowpodcast}>Show Podcast</Button>
        {/* <Button onClick={handleAudio}>Show Audio</Button> */}
        <Button onClick={handleQuranAudio}>Audio Quran</Button>
        <div>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            disabled={!query} // Disable the button if query is null or empty
            onClick={handleSearch}>
            Search
          </button>
        </div>
      </ButtonContainer>
      {showAdminUseForm && <Adminuse handleButtonClick={handleButtonClick} />}
      {/* {showview && (
        <UserTable data={showSurah} handleButtonClick={handleViewuser} />
      )} */}
      {showviewuser && (
        <UserTable data={showviewUser} handleButtonClick={handleViewuser} />
      )}
      {showunblock && <Unblockuser handleButtonClick={handleUnblock} />}

      {showaddpara && <Addpara handleButtonClick={handleAddpara} />}
      {showaddsurah && <Addsurah handleButtonClick={handleAddsurah} />}
      {showaddhadees && <Addhadees handleButtonClick={handleAddhadees} />}
      {showaddayat && <Addayat handleButtonClick={handleAddayat} />}
      {showarabicandenglish && (
        <ShowarabicandEnglish
          handleButtonClick={handleArabicandEnglish}
          showSurah={showSurah}
        />
      )}
      {showquranarabic && (
        <Showquranarabic
          handleButtonClick={handleQuranarabic}
          showPara={showPara}
        />
      )}
      {showPodcastform && (
        <Addpodcastform handleButtonClick={handleAddpodcast} />
      )}
      {showPodcast && <Showpodcast handleButtonClick={handleShowpodcast} />}
      {showquranenglish && (
        <Showenglishquran
          handleButtonClick={handleQuranenglish}
          showSurah={showSurah}
        />
      )}
      {showaudioquran && (
        <Showaudioquran
          handleButtonClick={handleQuranAudio}
          showSurah={showSurah}
        />
      )}
      {showsearch && (
        <SearchResults handleButtonClick={handleSearch} q={query} />
      )}
      {/* {showaudio && (
        <SurahPlayer handleButtonClick={handleAudio} audiodata={audio} />
      )} */}
    </>
  );
};

export default Adminpanel;
