import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components";

import AudioPlayer from "react-audio-player";

function SurahPlayer() {
  console.log("new page");
  const { roman_name } = useParams();
  const id=roman_name
  console.log(roman_name)
  const cookies = new Cookies();
  const [detail, setDetail] = useState([]); // Initialize as null for loading state
  const [error, setError] = useState(null); // Initialize as null for error handling
  const [surah,setSurah]=useState("")
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    axios(
      `https://themajesticquran.doctorsforhealth.co.uk/api/majestic/quranaudio?surah=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: cookies.get("token"),
        },
      }
    )
      .then((res) => {
        setDetail(res.data.message.audio);
        setSurah(res.data.message.roman_name)
        // setReceiveAyat(res.data[0].ayat);
        console.log("audio first", res.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [roman_name]);

  // detail.map((data, index) => {
  //   console.log("data", index, data.qari);

  //   // const b = () => {
  //   //   data.map((data2, index2) => {
  //   //     console.log(data2);
  //   //   });
  //   // };

  //   // Call the inner function 'b'
  //   // b();
  // });

 

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <h1>{surah}</h1>
      {detail.map((audio, index) => (
        <div key={index}>
          <h2>Qari{audio.qari}</h2>

          <AudioPlayer
            src={audio.qariaudio}
            autoPlay={false}
            controls
            onPlay={handleTogglePlay}
            onPause={handleTogglePlay}
          />
        </div>
      ))}
    </div>
  );
}

export default SurahPlayer;
