

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components";

const DetailPageContainer = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

const Notes = () => {
  const { roman_name } = useParams();
  const cookies = new Cookies();
  const [detail, setDetail] = useState(null);
  const [error, setError] = useState(null);
  const [noteTexts, setNoteTexts] = useState([]);


  useEffect(() => {
    axios(`http://localhost:2000/api/majestic/getnotes`, {
      method: "GET",
      headers: {
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setDetail(res.data.notes);
          console.log("notes", res.data);
        }
      })
      .catch((err) => {
        setError(err.message);
        console.error("Error fetching notes:", err);
      });
  }, []);

  const handleNoteTextChange = (index, newText) => {
    // Create a copy of the noteTexts array and update the value at the specified index
    const updatedNoteTexts = [...noteTexts];
    updatedNoteTexts[index] = newText;
    setNoteTexts(updatedNoteTexts);
  };

  const Confirm = (id,index) => {
   
    const obj = {
      noteText: noteTexts[index],
    };
    console.log("Update", obj);

    axios(`http://localhost:2000/api/majestic/updatenotetext?noteId=${id}`, {
      method: "PUT",
      data: obj,
      headers: {
        "Content-Type": "application/json",
        Authorization: cookies.get("token"),
      },
    })
      .then((res) => {
        console.log(res); // Check the structure of the response
        if (res.status === 200) {
          alert("Notes updated successfully");
        } else {
          alert("Notes update failed");
        }
      })
      .catch((error) => {
        console.error(error); // Log the error for debugging
        alert("An error occurred. Please try again.");
      });
  };

  return (
    <DetailPageContainer>
      {detail ? (
        <div>
          {detail.map((note,index) => (
            <div key={note._id}>
              <Title>Note Title: {note.ayat.title}</Title>
              <p>Starting Ayat: {note.ayat.starting_ayat}</p>
              <p>Ending Ayat: {note.ayat.ending_ayat}</p>
              <p>
                <b>Translation:</b> {note.ayat.paragraph}
              </p>
              <p>
                <b>Surah Name:</b> {note.ayat.surah.roman_name}
              </p>
              <p>
                <b>My note:</b> {note.text}
              </p>

              <textarea
                value={noteTexts[index]} // Use the noteText corresponding to the index
                onChange={(e) => handleNoteTextChange(index, e.target.value)}
                style={{ width: "100%", height: "100px" }}
              ></textarea>

              <button onClick={() => Confirm(note._id,index)}>Update note</button>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </DetailPageContainer>
  );
};

export default Notes;
