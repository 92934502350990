
import { BrowserRouter, Route, Routes, Link, Navigate } from "react-router-dom";
import { Component } from "react";

import Login from "./Pages/Login"
import Adminlogin from "./Pages/Adminlogin"
import Adminpanel from "./Pages/Adminpanel"
import Signup from "./Pages/Signup"
import Home from "./Pages/Home"
import Detailpage from  "./Pages/Detailpage";
import Showquranenglish from  "./Pages/showquranenglish";
import Detailpagepara from  "./Pages/detailpara";

import VideoPlayer from "./Pages/podcast"
import UpdatePodcast from "./Pages/update/Updatepodcast"
import Auth from "./Pages/Auth"
import SurahPlayer from "./Pages/Testaudio";

function App() {
  const isLogin = localStorage.getItem("login")


  return (
    <>
      <BrowserRouter>
        <Routes>
          

          {/* <Route path="/" element={<Auth Component={Login} />} /> */}
          <Route path="/" element={<Adminlogin/>} />
          <Route path="/adminpanel" element={<Auth Component={Adminpanel} />} />
          {/* <Route path="/register" element={<Signup/>} /> */}
          {/* <Route path="/home" element={<Home/>} /> */}
          <Route path="/test" element={<UpdatePodcast/>} />
          <Route path="/detail/:roman_name" element={<Detailpage/>} />
          <Route path="/detailenglish/:roman_name" element={<Showquranenglish/>} />
          <Route path="/detailaudio/:roman_name" element={<SurahPlayer/>} />
          <Route path="/detailpara/:roman_name" element={<Detailpagepara/>} />
       
          <Route path="/showpodcast" element={<VideoPlayer/>} />
        
    
       
     

        </Routes>

      </BrowserRouter>
    </>

  )

}

export default App;