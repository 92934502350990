import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components";

const DetailPageContainer = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Showquranenglish= () => {
  const { roman_name } = useParams();
  const cookies = new Cookies();
  const [detail, setDetail] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Initialize as null for error handling
  const [receiveAyat, setReceiveAyat] = useState(null); // Initialize as null for error handling

  useEffect(() => {
    axios(
      `https://themajesticquran.doctorsforhealth.co.uk/api/majestic/quranenglish?surah=${roman_name}`,
      {
        method: "GET",
        headers: {
          Authorization: cookies.get("token"),
        },
      }
    )
      .then((res) => {
        setDetail(res.data.message);
        // setReceiveAyat(res.data[0].ayat);
        console.log("ayat", (res.data));
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [roman_name]);

  // const Confirm = (id) => {
   
  //   const obj = {
  //     bookmark: id,
  //   };

  //   if (obj.bookmark) {
  //     axios("http://localhost:2000/api/majestic/updatebookmark", {
  //       method: "PUT",
  //       data: obj,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: cookies.get("token"),
  //       },
  //     })
  //       .then((res) => {
  //         console.log(res); // Check the structure of the response
  //         if (res.status === 200) {
  //           alert("Bookmark updated successfully");
  //         } else {
  //           alert("Bookmark update failed");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error); // Log the error for debugging
  //         alert("An error occurred. Please try again.");
  //       });
  //   } else {
  //     alert("Invalid data");
  //   }
  // };

  return (
    <>
    <DetailPageContainer>
      {detail ? (
        detail.map((content, index) => (
          <div key={index}>
            <Title>{content.surah.roman_name}</Title>
            <Title>{content.surah.arabic_name}</Title>
            {/* <button
                style={{ color: "blue" }}
                onClick={(e) => {
                  e.preventDefault();
                  Confirm(content._id);
                }}>
                Bookmark
              </button> */}
            <Title>{content.title}</Title>
            <Paragraph>Translation: {content.paragraph}</Paragraph>
          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </DetailPageContainer>
  </>
  );
};

export default Showquranenglish;
